import React, { useState } from "react";
import { uniqBy } from "lodash";

import { DateFilter } from "./common/DateFilter";
import Filter from "./common/Filter";

import moment from "moment";
import { useQualityIncentive } from "./api/caseManagementApi";
import Table from "./common/Table";
import PatientLink from "./patient/PatientLink";
import LoadingIndicator from "./common/LoadingIndicator";

const QUALITY_INCENTIVE_SERVICES = [
  "Pressure Ulcer(s)",
  "Urinary Tract Infection",
  "Indwelling Catheter",
  "Fall with Major Injury",
  "Antipsychotic Medications",
];

export const QualityIncentivePage = (props) => {
  const [startDate, setStartDate] = useState(moment().subtract(14, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [filters, setFilters] = useState({});

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const qipQuery = useQualityIncentive(startDate, endDate, page, pageSize);

  const handleDateClick = (dayCount) => {
    setStartDate(moment().subtract(dayCount, "days"));
    setEndDate(moment());
  };

  const handleDateFilterChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleFilterChange = (key, values) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (values.length === 0) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = values.map((x) => x.label);
      }

      return updatedFilters;
    });
  };

  const getButtonStyle = (daySpan) => {
    const isActive = startDate.isSame(moment().subtract(daySpan, "days"), "day");
    return `btn m-r ${isActive ? "btn-primary" : "btn-default"}`;
  };

  if (qipQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (qipQuery.isError) {
    return (
      <div className="d-flex align-center" style={{ padding: "2rem" }}>
        <span className="flex-1-auto text-center">Error loading patient list. Please try again.</span>
      </div>
    );
  }

  const filteredData = getFilteredData(qipQuery.data.qipData, filters);

  filteredData.sort((a, b) => {
    const dateA = moment(a.service.date);
    const dateB = moment(b.service.date);

    return dateB - dateA;
  });

  const config = getConfig(page, pageSize, filteredData.length, setPage, handlePageSizeChange);

  let filterOptions = {
    itemSet: [
      "Assessment-based",
      // "Function-based"
    ].map((status) => {
      return {
        label: status,
        value: status,
      };
    }),
    categories: QUALITY_INCENTIVE_SERVICES.map((service) => ({ label: service, value: service })),
    location: uniqBy(
      qipQuery.data.qipData.map((x) => {
        return { label: x.facility.name, value: x.facility.id };
      }),
      "value"
    ),
  };

  let qipFilters = {};

  return (
    <>
      <div className="updates-panel m-b-sm">
        <h1 className="h2 text-primary flex-1-0-auto">Quality Incentive Tracking</h1>
        <button onClick={() => handleDateClick(30)} className={getButtonStyle(30)}>
          Last 30 Days
        </button>
        <button onClick={() => handleDateClick(14)} className={getButtonStyle(14)}>
          Last 14 Days
        </button>
        <DateFilter endDate={endDate} startDate={startDate} setDateFilter={handleDateFilterChange} />
      </div>
      <div className="hpanel">
        <div className="panel-body no-padding">
          <Table
            config={config}
            data={filteredData}
            isLoading={qipQuery.isLoading}
            headerContent={
              <div className="flex-column">
                {Object.keys(filterOptions).map((filterKey) => {
                  const label =
                    filterKey === "itemSet" ? "Item Set" : filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
                  return (
                    <Filter
                      className="flex-1-auto p-xs"
                      key={filterKey}
                      label={label}
                      value={qipFilters[filterKey]}
                      options={filterOptions[filterKey]}
                      onChange={(values) => handleFilterChange(filterKey, values)}
                      isMulti
                    />
                  );
                })}
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

const getConfig = (page, pageSize, totalCount, setPage, setPageSize) => ({
  columns: [
    {
      title: "Last Documented",
      transformer: (data) => moment(data.service.date).format("M/DD/YYYY"),
    },

    {
      title: "Patient",
      transformer: (data) => <PatientLink stayId={data.admit_id} patient={data.patient} />,
    },
    {
      title: "Item Set",
      transformer: (data) => "Assessment-based",
    },
    {
      title: "Category",
      transformer: (data) => data.service.desc,
    },
    // {
    //   title: "Last ARD",
    // },
    // {
    //   title: "Days Since Last ARD",
    // },
    {
      title: "Location",
      transformer: (data) => data.facility.name,
    },
  ],
  search: {
    searchCounterName: "patient",
  },
  category: "Patients",
  pagination: {
    enabled: true,
    page: page,
    pageSize: pageSize,
    pageSizeOptions: [25, 50, 100],
    totalCount: totalCount,
    setPage: setPage,
    setPageSize: setPageSize,
  },
});

const getFilteredData = (data, filters) => {
  return data.filter((item) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key] || filters[key].length === 0) {
        return true;
      }

      if (key === "location") {
        return filters[key].includes(item.facility.name);
      } else if (key === "categories") {
        return filters[key].includes(item.service.desc);
      }

      return true;
    });
  });
};
